import React from "react"
import styled from "styled-components"
import { Box, ResponsiveContext } from "grommet"

import RemoteTeam from "../../assets/remoteTeam.svg"
import SectionTitle from "../Typography/SectionTitle"
import Wrapper from "../Container/Wrapper"

const MapBox = styled(Box)`
  border-radius: 6px;
  filter: drop-shadow(0px 24px 30px rgba(0, 0, 0, 0.06));
  position: realtive;
`
// const TeamBox = styled(Box)`
//   position: absolute;
//   bottom: 24px;
//   left: 24px;
// `
const Philosophy = () => {
  // const dotColor = "accent-1-dark"
  // const places = [
  //   {
  //     name: "Basel - Schweiz",
  //     location: [47.559601, 7.588576],
  //     color: dotColor,
  //     onClick: () => console.log("Sydney"),
  //   },
  //   {
  //     name: "Kherson - Ukraine",
  //     location: [46.635418, 32.616867],
  //     color: dotColor,
  //     onClick: () => console.log("Sydney"),
  //   },
  //   {
  //     name: "Korolyov - Russland",
  //     location: [55.9075631, 37.7826397],
  //     color: dotColor,
  //     onClick: () => console.log("Sydney"),
  //   },
  //   {
  //     name: "Oxford - Grossbritanien",
  //     location: [51.752022, -1.257726],
  //     color: dotColor,
  //     onClick: () => console.log("Sydney"),
  //   },
  //   {
  //     name: "Da Nang - Vietnam",
  //     location: [16.054407, 108.202164],
  //     color: dotColor,
  //     onClick: () => console.log("Sydney"),
  //   },
  //   {
  //     name: "Tashkent - Usbekistan",
  //     location: [41.299496, 69.240074],
  //     color: dotColor,
  //     onClick: () => console.log("Sydney"),
  //   },
  // ]

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Wrapper direction="row" align="center" wrap>
          <Box
            direction="column"
            margin={size === "small" ? { bottom: "medium" } : ""}
            basis={size === "small" ? "1" : "1/3"}
          >
            <SectionTitle
              size={size}
              title="philosophy:title"
              subtitle="philosophy:subtitle"
              desc="philosophy:desc"
            />
          </Box>
          <Box
            pad={
              size === "small"
                ? {}
                : size === "medium"
                ? { left: "large" }
                : { left: "xlarge" }
            }
            basis={size === "small" ? "full" : "2/3"}
          >
            <MapBox
              align="end"
              basis="full"
              pad="large"
              style={size === "small" ? { maxHeight: 290 } : { maxHeight: 400 }}
            >
              <RemoteTeam style={{ height: "100%", width: "100%" }} />
              {/* <TeamBox>
                <H2 spacer>12</H2>
                <P margin="none" size="small">
                  Perineers
                </P>
              </TeamBox>
              <WorldMap
                hoverColor="brand"
                fill
                places={places}
                style={{ cursor: "pointer" }}
              /> */}
            </MapBox>
          </Box>
        </Wrapper>
      )}
    </ResponsiveContext.Consumer>
  )
}

export default Philosophy
