import React from "react"
import { Box } from "grommet"

import { P } from "../Typography"

const Chip = ({ type, color = "lightgreen", ...rest }) => {
  return (
    <Box
      style={{
        borderRadius: "4px",
        width: "fit-content",
        position: "relative",
      }}
      pad={{ horizontal: "xsmall" }}
      {...rest}
    >
      <Box
        background={color}
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          opacity: 0.2,
          left: 0,
          borderRadius: "4px",
        }}
      />
      <P
        color={color}
        margin={{ vertical: "0" }}
        style={{
          textTransform: "Uppercase",
          fontWeight: 700,
          fontSize: 10,
        }}
      >
        {type}
      </P>
    </Box>
  )
}

export default Chip
