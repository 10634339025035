import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { TextInput, TextArea, Box, ResponsiveContext } from "grommet"
import { useTranslation } from "react-i18next"

import Button from "../../components/Buttons/InternalButton"
import SmallDialog from "../../components/Dialog/SmallMessage"

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const InputArea = styled(TextArea)`
  font-weight: 300;
  font-size: 18px;
`

const Input = styled(TextInput)`
  height: 47px;
  font-weight: 300;
`

const ContactSection = () => {
  const { t, i18n } = useTranslation()
  const [state, setState] = useState({})
  const [dialog, setDialog] = useState(false)

  useEffect(() => {
    setState()
  }, [i18n.language])

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    }).catch(error => alert(error))
  }

  const handleSubmitClick = () => {
    setDialog(true)
    setTimeout(function() {
      setDialog(false)
    }, 3000)
  }

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <>
          {dialog && (
            <SmallDialog
              heading={t("contact:smDialogHeading")}
              message={t("contact:smDialogMessage")}
              farewell={t("contact:smDialogFarewell")}
            />
          )}
          <form
            name="Contact Periplus AG"
            method="post"
            action="/"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={handleSubmit}
          >
            {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
            <input type="hidden" name="form-name" value="contact" />
            <p hidden>
              <label>
                Don’t fill this out:{" "}
                <input name="bot-field" onChange={handleChange} />
              </label>
            </p>
            <Box direction="column">
              <Box direction="row">
                <Box pad={{ bottom: "medium" }} style={{ width: "100%" }}>
                  <Input
                    placeholder={t("forms:name")}
                    type="text"
                    name="name"
                    onChange={handleChange}
                  />
                </Box>
                <Box
                  pad={{ bottom: "medium", left: "medium" }}
                  style={{ width: "100%" }}
                >
                  <Input
                    placeholder={t("forms:email")}
                    type="email"
                    name="email"
                    primary
                    onChange={handleChange}
                  />
                </Box>
              </Box>
              <Box direction="row">
                <Box pad={{ bottom: "medium" }} style={{ width: "100%" }}>
                  <Input
                    placeholder={t("forms:phone")}
                    type="text"
                    name="phone"
                    onChange={handleChange}
                  />
                </Box>
                <Box
                  pad={{ bottom: "medium", left: "medium" }}
                  style={{ width: "100%" }}
                >
                  <Input
                    placeholder={t("forms:job")}
                    type="text"
                    name="job"
                    onChange={handleChange}
                  />
                </Box>
              </Box>
              <Box pad={{ bottom: "medium" }} style={{ width: "100%" }}>
                <InputArea
                  placeholder={t(`forms:message`)}
                  primary
                  name="message"
                  onChange={handleChange}
                  resize={false}
                  fill
                />
              </Box>
              <Box alignSelf="start">
                <Box pad={{ right: "medium" }} style={{ width: "100%" }}>
                  <Button
                    pad="medium"
                    fill={false}
                    type="submit"
                    onClick={handleSubmitClick}
                  >
                    {t("contact:getInTouch")}
                  </Button>
                </Box>
              </Box>
            </Box>
          </form>
        </>
      )}
    </ResponsiveContext.Consumer>
  )
}

export default ContactSection
