import React from "react"
import Img from "gatsby-image"
import { Box } from "grommet"
import { useTranslation } from "react-i18next"

import { H3, P } from "../Typography"
import Link from "../Link/Link"
import Chip from "../Elements/Chip"
import { FloatingCard } from "../../styles/styledElements"

const ReadMore = ({ routeInternal }) => {
  return <Link to={routeInternal}>button:readMore</Link>
}

const CaseCard = ({
  color,
  data,
  readMore,
  readTime,
  routeInternal,
  size,
  ...rest
}) => {
  const { t, i18n } = useTranslation()

  const routeInternalLang =
    i18n.language === "en" ? routeInternal : `de/${routeInternal}`

  const Card = ({ data }) => {
    return (
      <FloatingCard
        direction="column"
        background="white"
        style={{ position: "relative", borderRadius: "4px", height: "100%" }}
        justify="start"
        {...rest}
      >
        <Box style={{ position: "relative" }}>
          {data.img && (
            <Img
              fluid={data.img}
              style={{
                borderTopRightRadius: "4px",
                borderTopLeftRadius: "4px",
                position: "relative",
                width: "100%",
                height: "100%",
                top: 0,
              }}
            />
          )}
        </Box>
        <Box
          pad="medium"
          justify="start"
          style={{
            minHeight: "auto",
            zIndex: 2,
            flex: 1,
          }}
        >
          <Box style={{ flex: 1 }}>
            <Chip color={color} type={data.tag} margin={{ bottom: "small" }} />
            <H3 size="small">{data.title}</H3>
            <P size="small" margin="0">
              {data.subtitle}
            </P>
          </Box>
          {readMore && (
            <Box
              direction="row"
              justify="between"
              align="center"
              margin={{ top: "small" }}
            >
              <Box>
                <ReadMore routeInternal={routeInternalLang} />
              </Box>
              <Box>
                <P margin="0" size="small">
                  {`${readTime} ` + t("blog:readDuration")}
                </P>
              </Box>
            </Box>
          )}
        </Box>
      </FloatingCard>
    )
  }

  return readMore ? (
    <Link style={{ flex: 1 }} to={routeInternalLang}>
      <Card data={data} />
    </Link>
  ) : (
    <Card style={{ flex: 1 }} data={data} />
  )
}

export default CaseCard
