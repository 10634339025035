import React from "react"
import { Box, ResponsiveContext, Carousel } from "grommet"
import styled from "styled-components"

import { P } from "../Typography"
import Wrapper from "../Container/Wrapper"
import { theme } from "../../styles/theme"

import Android from "../../assets/Icons/TechStack/android.svg"
import Apollostack from "../../assets/Icons/TechStack/apollostack.svg"
import Azure from "../../assets/Icons/TechStack/azure.svg"
import CSharp from "../../assets/Icons/TechStack/c-sharp.svg"
import Cloudflare from "../../assets/Icons/TechStack/cloudflare.svg"
import Docker from "../../assets/Icons/TechStack/docker-icon.svg"
import DotNET from "../../assets/Icons/TechStack/dotnet.svg"
import Kubernetes from "../../assets/Icons/TechStack/kubernetes.svg"
import NodeJS from "../../assets/Icons/TechStack/nodejs.svg"
import PostgreSQL from "../../assets/Icons/TechStack/postgresql.svg"
import ReactLogo from "../../assets/Icons/TechStack/reactLogo.svg"
import Hasura from "../../assets/Icons/TechStack/hasura.svg"

import Terraform from "../../assets/Icons/TechStack/terraform.svg"
import TypeScript from "../../assets/Icons/TechStack/typescript-icon.svg"
import Python from "../../assets/Icons/TechStack/python.svg"
import Kibana from "../../assets/Icons/TechStack/kibana.svg"
import Prometheus from "../../assets/Icons/TechStack/prometheus.svg"
import Grafana from "../../assets/Icons/TechStack/grafana.svg"
import SectionTitle from "../Typography/SectionTitle"

export const HoverBox = styled(Box)`
  filter: grayscale(1);
  transition: transform 0.4s ease, box-shadow 0.4s ease;
  transition: transform 0.4s ease, box-shadow 0.4s ease,
    -webkit-transform 0.4s ease;

  &:hover {
    filter: grayscale(0);
    -webkit-perspective: 1px;
    perspective: 1px;
    -webkit-transform: perspective(0) translate(0, -5px);
    transform: perspective(0) translate(0, -5px);
  }
`

export const HoverIcon = styled(Box)`
  filter: grayscale(1);

  &:hover {
    filter: grayscale(0);
  }
`

export const HoverTo = styled(Box)`
  color: white;
  &:hover {
    color: ${theme.global.colors.paragraphDark};
  }
`

export const CarouselLink = styled.a`
  color: white;
  &:hover {
    color: ${theme.global.colors.paragraphDark};
  }
`

const stack = [
  {
    name: "Android",
    icon: <Android style={{ alignSelf: "center" }} />,
    link: "https://www.android.com/",
  },
  {
    name: "Apollo",
    icon: <Apollostack />,
    link: "https://www.apollographql.com/",
  },
  {
    name: "Azure Cloud Services",
    icon: <Azure />,
    link: "https://azure.microsoft.com/",
  },
  {
    name: "C-Sharp",
    icon: <CSharp />,
    link: "https://docs.microsoft.com/dotnet/csharp/",
  },
  {
    name: "Cloudflare",
    icon: <Cloudflare />,
    link: "https://www.cloudflare.com/",
  },
  {
    name: "Docker",
    icon: <Docker />,
    link: "https://www.docker.com/",
  },
  {
    name: ".NET",
    icon: <DotNET />,
    link: "https://dotnet.microsoft.com/",
  },
  {
    name: "Kubernetes",
    icon: <Kubernetes />,
    link: "https://kubernetes.io/",
  },
  {
    name: "Node.js",
    icon: <NodeJS />,
    link: "https://nodejs.org/",
  },
  {
    name: "PostgreSQL",
    icon: <PostgreSQL />,
    link: "https://www.postgresql.org/",
  },
  {
    name: "ReactJS",
    icon: <ReactLogo />,
    link: "https://reactjs.org/",
  },
  {
    name: "Hasura",
    icon: <Hasura />,
    early: true,
    link: "https://hasura.io/",
  },

  {
    name: "Terraform",
    icon: <Terraform />,
    link: "https://www.terraform.io/",
  },

  {
    name: "Python",
    icon: <Python />,
    link: "https://www.python.org/",
  },

  {
    name: "Kibana",
    icon: <Kibana />,
    link: "https://www.elastic.co/de/kibana",
  },

  {
    name: "Prometheus",
    icon: <Prometheus />,
    link: "https://prometheus.io/",
  },
  {
    name: "Grafana",
    icon: <Grafana />,
    link: "https://grafana.com/",
  },
  {
    name: "TypeScript",
    icon: <TypeScript />,
    link: "https://www.typescriptlang.org/",
  },
]

const TechStack = () => {
  const rowItems = {
    small: "1/3",
    medium: "1/4",
    large: "1/4",
  }

  const displayItems = {
    small: "3",
    medium: "12",
    large: "12",
  }

  const randomNumber = (min, max) => {
    return Math.floor(Math.random() * (max - min) + min)
  }

  const CarouselComp = ({ initialChild, children, size }) => {
    const randomness = randomNumber(5000, 5000)
    return (
      <HoverBox
        basis={rowItems[size]}
        pad={{ vertical: "xsmall" }}
        justify="center"
        align="center"
        direction="column"
        style={{
          width: "100px",
          height: "130px",
          position: "relative",
        }}
      >
        <Carousel
          fill
          alignSelf="center"
          style={{ alignItems: "center" }}
          initialChild={initialChild}
          controls={false}
          play={randomness}
        >
          {children}
        </Carousel>
      </HoverBox>
    )
  }

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Wrapper
          direction={size === "small" ? "column-reverse" : "row"}
          align="center"
          wrap
        >
          <Box
            pad={
              size === "small"
                ? {}
                : size === "medium"
                ? { right: "large" }
                : { right: "xlarge" }
            }
            basis={size === "small" ? "1" : "2/3"}
            style={{ width: "100%" }}
          >
            <Box
              align="start"
              basis="full"
              style={{ width: "100%", marginRight: "-48px" }}
            >
              <Box round="small" style={{ width: "100%" }} direction="row" wrap>
                {stack.slice(0, displayItems[size]).map((i, index) => (
                  <CarouselComp initialChild={index} size={size} key={index}>
                    {stack.map(item => (
                      <>
                        <CarouselLink
                          href={item.link}
                          target="_blank"
                          style={{
                            width: "100%",
                            height: "100%",
                            position: "relative",
                          }}
                        >
                          <HoverTo
                            style={{
                              height: "100%",
                              position: "relative",
                              zIndex: 2,
                            }}
                            justify="center"
                            pad="small"
                            key={item.name}
                          >
                            <Box
                              pad={{ right: "medium" }}
                              style={{
                                position: "absolute",
                                bottom: "35px",
                                width: "100%",
                              }}
                            >
                              {item.early && (
                                <Box
                                  background="brand"
                                  round="xsmall"
                                  pad={{
                                    horizontal: "xsmall",
                                  }}
                                  align="center"
                                  alignSelf="center"
                                  style={{
                                    zIndex: 2,
                                    boxShadow:
                                      "rgba(0, 0, 0, 0.25) 0px 0px 10px",
                                  }}
                                >
                                  <P
                                    margin="none"
                                    width="auto"
                                    style={{
                                      color: "white",
                                      fontSize: "12px",
                                      textAlign: "center",
                                    }}
                                  >
                                    Early Adopter
                                  </P>
                                </Box>
                              )}
                            </Box>
                            {item.icon}
                            <p
                              style={{
                                color: "inherit",
                                fontSize: "11px",
                                margin: "0",
                                textAlign: "center",
                              }}
                            >
                              {item.name}
                            </p>
                          </HoverTo>
                        </CarouselLink>
                      </>
                    ))}
                  </CarouselComp>
                ))}
              </Box>
            </Box>
          </Box>
          <Box
            direction="column"
            margin={size === "small" ? { bottom: "large" } : ""}
            basis={size === "small" ? "1" : "1/3"}
          >
            <SectionTitle
              size={size}
              title="techstack:title"
              subtitle="techstack:subtitle"
              desc="techstack:desc"
            />
          </Box>
        </Wrapper>
      )}
    </ResponsiveContext.Consumer>
  )
}

export default TechStack
