import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { Box } from "grommet"

import { H3, P } from "../Typography"
import CardTransitionVertical from "../../assets/CardTransitionVertical.svg"
import { theme } from "../../styles/theme"

const FloatingCard = styled(Box)`
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.14),
    inset 10px 0px 0px rgba(0, 0, 0, 1%);
  filter: drop-shadow(0px 5px 4px rgba(0, 0, 0, 0.06));
`

const ProductCard = ({ data, size, ...rest }) => {
  const themeRadius = theme.global.control.border.radius

  const borderRadius =
    size === "small"
      ? { borderTopLeftRadius: themeRadius, borderTopRightRadius: themeRadius }
      : {
          borderTopLeftRadius: themeRadius,
          borderBottomLeftRadius: themeRadius,
        }

  return (
    <Box pad="small" height={size === "small" ? "500px" : "400px"}>
      <FloatingCard
        style={{ borderRadius: theme.global.control.border.radius }}
        direction={size === "small" ? "column" : "row"}
        background="white"
        height={size === "small" ? "500px" : "400px"}
        {...rest}
      >
        <Box
          basis={size === "small" ? "1/2" : "1/3"}
          style={{ position: "relative", overflow: "hidden" }}
        >
          <Box
            background={data.background}
            style={{
              ...borderRadius,
              height: "100%",
            }}
            justify="end"
          >
            {data.img && (
              <Img
                fluid={data.img}
                style={{
                  mixBlendMode: "lighten",
                  position: "absolute",
                  height: "100%",
                  width: "100%",
                  ...borderRadius,
                }}
              />
            )}
            <Box pad="large">{data.logo}</Box>
          </Box>
          {size === "small" ? null : (
            <CardTransitionVertical
              style={{ height: "100%", position: "absolute", right: 0 }}
            />
          )}
        </Box>
        <Box
          basis="2/3"
          pad={
            size === "small"
              ? { horizontal: "80px", vertical: "large" }
              : { horizontal: "medium", right: "xlarge" }
          }
          justify="center"
          style={{
            minHeight: "auto",
          }}
        >
          <H3 size="small">{data.title}</H3>

          <P margin={{ vertical: "xsmall" }}>{data.description}</P>
        </Box>
      </FloatingCard>
    </Box>
  )
}

export default ProductCard
