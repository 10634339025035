import styled from "styled-components"
import { Box } from "grommet"

import { theme } from "./theme"

export const HoverBox = styled(Box)`
  border-radius: ${theme.global.control.border.radius};
  filter: grayscale(0.3);
  box-shadow: ${theme.global.shadows.boxPurple};

  &:hover {
    filter: grayscale(0);
    box-shadow: ${theme.global.shadows.boxPurpleHover};
  }
`

export const FloatingCard = styled(Box)`
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.14),
    inset 10px 0px 0px rgba(0, 0, 0, 1%);
  filter: drop-shadow(0px 24px 30px rgba(0, 0, 0, 0.06));

  -webkit-transition: box-shadow 0.4s ease, -webkit-transform 0.4s ease;
  transition: box-shadow 0.4s ease, -webkit-transform 0.4s ease;
  transition: transform 0.4s ease, box-shadow 0.4s ease;
  transition: transform 0.4s ease, box-shadow 0.4s ease,
    -webkit-transform 0.4s ease;
  &:hover {
    box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.2);
    -webkit-perspective: 1px;
    perspective: 1px;
    -webkit-transform: perspective(0) translate(0, -5px);
    transform: perspective(0) translate(0, -5px);
  }
`
