import React from "react"
import styled from "styled-components"
import { Box, ResponsiveContext } from "grommet"

import SectionTitle from "../Typography/SectionTitle"
import Wrapper from "../Container/Wrapper"
import ContactSection from "../Forms/ContactSection"

const ContactBox = styled(Box)`
  background: white;
  border-radius: 6px;
  filter: drop-shadow(0px 24px 30px rgba(0, 0, 0, 0.06));
  position: realtive;
`

const KnowMore = () => {
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Wrapper direction="row" align="center" wrap>
          <Box
            direction="column"
            basis={size === "small" ? "full" : "1/3"}
            margin={{ top: "-6px" }}
          >
            <SectionTitle
              size={size}
              title="knowMore:title"
              desc="knowMore:desc"
            />
          </Box>
          <Box
            basis={size === "small" ? "full" : "2/3"}
            pad={
              size === "small"
                ? {}
                : size === "medium"
                ? { left: "large" }
                : { left: "xlarge" }
            }
          >
            <ContactBox pad="large">
              <ContactSection />
            </ContactBox>
          </Box>
        </Wrapper>
      )}
    </ResponsiveContext.Consumer>
  )
}

export default KnowMore
