import React from "react"
import { useTranslation } from "react-i18next"
import { Button as GrommetButton, Paragraph } from "grommet"
import { isObject } from "grommet/utils"
import { navigate } from "gatsby"

const InternalButton = ({
  children,
  hoverIndicator = true,
  psize = "small",
  route,
  ...rest
}) => {
  const { t } = useTranslation()
  const destructObject = isObject(children) ? children.props.children : children
  return (
    <GrommetButton
      color="brand"
      primary
      onClick={event => {
        event.preventDefault()
        navigate(route)
      }}
      hoverIndicator={{ color: "light-3" }}
      style={{ padding: "6px", textAlign: "center" }}
      {...rest}
    >
      <Paragraph
        size={psize}
        style={{
          lineHeight: 1.55,
          maxWidth: "100%",
        }}
        margin={{ horizontal: "xsmall", vertical: "none" }}
      >
        {t(destructObject)}
      </Paragraph>
    </GrommetButton>
  )
}

export default InternalButton
