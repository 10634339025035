import React from "react"
import { Box, ResponsiveContext } from "grommet"
import { graphql, useStaticQuery } from "gatsby"
import { useTranslation } from "react-i18next"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"

import SectionTitle from "../Typography/SectionTitle"
import Wrapper from "../Container/Wrapper"
import ProductCard from "../Cards/ProductCard"
import { theme } from "../../styles/theme"
import WeDeclareLogo from "../../assets/WeDeclareLogo.svg"
import AditLogo from "../../assets/AditLogo.svg"
import SpoorLogo from "../../assets/SpoorLogo.svg"

const Products = () => {
  const { t } = useTranslation()

  const data = useStaticQuery(graphql`
    query Products {
      allImageSharp(
        filter: { fluid: { originalImg: { regex: "/Products/" } } }
      ) {
        edges {
          node {
            id
            fluid(quality: 30) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)

  const getImage = project => {
    let source = ""
    data.allImageSharp.edges.map(image => {
      if (image.node.fluid.src.includes(project)) {
        source = image.node.fluid
      }
      return source
    })
    return source
  }

  const constructedData = {
    wedeclare: {
      name: "WeDeclare",
      logo: <WeDeclareLogo />,
      title: t("products:weDeclareTitle"),
      subTitle: t("products:weDeclareSubtitle"),
      description: t("products:weDeclareDesc"),
      img: getImage("WeDeclare"),
      background: theme.global.gradients.adit,
    },
    adit: {
      name: "Adit",
      logo: <AditLogo />,
      title: t("products:aditTitle"),
      subTitle: t("products:aditSubtitle"),
      description: t("products:aditDesc"),
      img: getImage("Adit"),
      background: theme.global.gradients.adit,
    },
    spoor: {
      name: "Spoor",
      logo: <SpoorLogo />,
      title: t("products:spoorTitle"),
      subTitle: t("products:spoorSubtitle"),
      description: t("products:spoorDesc"),
      img: getImage("Spoor"),
      background: theme.global.gradients.spoor,
    },
  }

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1 },
      items: 1,
    },
  }

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Wrapper align="center" horizontal="0">
          <Box justify="center" pad={{ bottom: "medium" }}>
            <SectionTitle
              variant="light"
              spacer
              size={size}
              title="products:title"
              desc="products:subtitle"
              textAlign="center"
            />
          </Box>
          <Box width="100%">
            <Carousel
              responsive={responsive}
              ssr // means to render carousel on server-side.
              infinite={true}
              autoPlay={false}
              autoPlaySpeed={8000}
              keyBoardControl={true}
              customTransition="transform 300ms ease-in-out"
              transitionDuration={2000}
              renderButtonGroupOutside={true}
              containerClass="carousel-container"
              dotListClass="custom-dot-list-style"
              itemClass={
                size === "small"
                  ? "carousel-item-padding-40-px-small"
                  : "carousel-item-padding-40-px"
              }
            >
              <ProductCard size={size} data={constructedData.wedeclare} />

              <ProductCard size={size} data={constructedData.adit} />

              <ProductCard size={size} data={constructedData.spoor} />
            </Carousel>
          </Box>
        </Wrapper>
      )}
    </ResponsiveContext.Consumer>
  )
}

export default Products
