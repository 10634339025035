import React from "react"
import { graphql } from "gatsby"
import { Box, ResponsiveContext } from "grommet"
import Img from "gatsby-image"
import { useTranslation } from "react-i18next"

import Layout from "../components/Layout"
import SEO from "../components/seo"

import Landing from "../pages/Landing"
import Philosophy from "../components/Sections/Philosophy"
import TechStack from "../components/Sections/TechStack"
import Showcase from "../components/Sections/Showcase"

import TriangleSection from "../components/Elements/TriangleSection"
import TriangleBottom from "../components/Elements/TriangleBottom"
import { theme } from "../styles/theme"
import {
  RectangleGray,
  RectangleDarkerGray,
} from "../components/Elements/RectangleLight"
import KnowMore from "../components/Sections/KnowMore"

import Products from "../components/Sections/Products"

export const SectionWrapper = ({ children, ...rest }) => {
  return (
    <Box pad={{ vertical: "medium" }} align="center" {...rest}>
      <Box width="xlarge" direction="column">
        {children}
      </Box>
    </Box>
  )
}

const Index = data => {
  const { t } = useTranslation()
  return (
    <Layout style={{ overflow: "hidden" }}>
      <ResponsiveContext.Consumer>
        {size => (
          <Box style={{ overflow: "hidden" }}>
            <SEO title={t("seo:home")} />

            <Box
              align="center"
              background={theme.global.gradients.purpleBlueDark}
              style={{
                position: "relative",

                zIndex: 0,
              }}
            >
              <Box
                style={{
                  background:
                    "linear-gradient(180deg, rgba(33,30,50,1) 0%, rgba(255,255,255,0) 100%)",
                  position: "absolute",
                  height: "40%",
                  width: "100%",
                  zIndex: 2,
                }}
              />
              <Img
                fluid={data.data.file.childImageSharp.fluid}
                style={{
                  opacity: "0.2",
                  background: "#393272",
                  position: "absolute",
                  height: "100%",
                  width: "100%",
                }}
              />

              <Box
                background={theme.global.gradients.blueToDark}
                style={{
                  background: "#393272",
                  position: "absolute",
                  height: "100%",
                  width: "100%",
                  mixBlendMode: "lighten",
                }}
              />
              <Box width="xlarge" direction="column" style={{ zIndex: 2 }}>
                <Landing size={size} />
              </Box>

              <TriangleBottom zIndex={0} color="#f8f9fb" size={size} />
              <TriangleSection size={size} bottom="0" />
              <TriangleSection size={size} variant="medium" />
            </Box>

            <SectionWrapper
              background="accent-5"
              style={{ position: "relative" }}
            >
              <Philosophy />
              <RectangleGray top="-21rem" />
            </SectionWrapper>

            <SectionWrapper style={{ overflow: "hidden" }}>
              <TechStack />
              {/* <RectangleGray top="120rem" degrees={31} /> */}
            </SectionWrapper>

            <SectionWrapper
              style={{ position: "relative", overflow: "hidden" }}
              background={theme.global.gradients.purpleBlueDark}
            >
              <RectangleGray top="20rem" height="25rem" degrees={6} />
              <Products />
              <Box
                background="white"
                width="100%"
                style={{
                  position: "absolute",
                  left: 0,
                  bottom: 0,
                  height: "192px",
                }}
              />

              <TriangleBottom size={size} zIndex={0} bottom="192px" />
              <TriangleSection size={size} bottom="192px" />
              <TriangleSection size={size} bottom="192px" variant="medium" />
            </SectionWrapper>

            <SectionWrapper>
              <Showcase />
            </SectionWrapper>

            <SectionWrapper style={{ position: "relative" }}>
              <KnowMore />
              <RectangleDarkerGray top="28rem" degrees={-19} />
              <RectangleDarkerGray top="-21rem" degrees={12} />
              <Box pad={{ bottom: "xlarge" }} />
            </SectionWrapper>
          </Box>
        )}
      </ResponsiveContext.Consumer>
    </Layout>
  )
}

export default Index

export const query = graphql`
  query Image {
    file(relativePath: { eq: "Header.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 50) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
