import React from "react"
import { Box, ResponsiveContext, Grid } from "grommet"
import { graphql, useStaticQuery } from "gatsby"
import { useTranslation } from "react-i18next"

import SectionTitle from "../Typography/SectionTitle"
import Wrapper from "../Container/Wrapper"
import CaseCard from "../Cards/CaseCard"

const Showcase = () => {
  const { t } = useTranslation()

  const data = useStaticQuery(graphql`
    query UseCases {
      allImageSharp(
        filter: { fluid: { originalImg: { regex: "/UseCases/" } } }
      ) {
        edges {
          node {
            id
            fluid(quality: 50) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)

  const getImage = project => {
    let source = ""
    data.allImageSharp.edges.map(image => {
      if (image.node.fluid.src.includes(project)) {
        source = image.node.fluid
      }
      return source
    })
    return source
  }

  const constructedData = {
    spoor: {
      name: "Spoor",
      title: t("showcase:spoorTitle"),
      tag: t("showcase:aditTag"),
      subtitle: t("showcase:spoorSubtitle"),
      description: t("showcase:spoorDesc"),
      img: getImage("Spoor"),
    },
    spifoperations: {
      name: "spifoperations",
      title: t("showcase:spifoperationsTitle"),
      tag: t("showcase:aditTag"),
      subtitle: t("showcase:spifoperationsSubtitle"),
      description: t("showcase:spifoperationsDesc"),
      img: getImage("SPIF"),
    },
    adit: {
      name: "adit",
      title: t("showcase:aditTitle"),
      tag: t("showcase:aditTag"),
      subtitle: t("showcase:aditSubtitle"),
      description: t("showcase:aditDesc"),
      img: getImage("Adit"),
    },
  }

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Wrapper align="center" top="small">
          <Box justify="center" pad={{ bottom: "medium" }}>
            <SectionTitle
              size={size}
              spacer
              title="showcase:title"
              subtitle="showcase:subtitle"
              textAlign="center"
              margin={{ bottom: "medium", top: "none" }}
            />
          </Box>
          <Box width="100%">
            <Grid
              columns={{
                count: size === "large" ? 3 : size === "medium" ? 2 : 1,
                size: "flex",
              }}
              gap="medium"
              alignContent="stretch"
            >
              <CaseCard
                size={size}
                readMore
                readTime={4}
                color="DarkTurquoise"
                routeInternal="stories-2020-adit-mr"
                data={constructedData["adit"]}
              />
              <Box>
                <CaseCard
                  color="DarkTurquoise"
                  readMore
                  readTime={9}
                  size={size}
                  data={constructedData["spoor"]}
                  routeInternal="stories-2020-spoor-realtime-cargo-monitoring"
                />
              </Box>
              <Box style={{ opacity: 0.5 }}>
                <CaseCard
                  size={size}
                  color="DarkTurquoise"
                  data={constructedData["spifoperations"]}
                />
              </Box>
            </Grid>
          </Box>
        </Wrapper>
      )}
    </ResponsiveContext.Consumer>
  )
}

export default Showcase
