import React from "react"
import { Box, ResponsiveContext, Layer, Paragraph, Heading } from "grommet"

const SmallMessage = ({
  heading,
  message,
  farewell,
  background = "accent-1",
}) => {
  const weight = { fontWeight: 300 }
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Layer
          position="bottom"
          modal={false}
          margin={{ vertical: "large", horizontal: "none" }}
          responsive={false}
          plain
          style={size === "small" ? { width: "80%" } : {}}
        >
          <Box
            align="center"
            direction="column"
            gap="small"
            justify="between"
            round="xsmall"
            elevation="medium"
            pad="medium"
            background={background}
            style={{ color: "#FFF" }}
          >
            <Heading
              level="3"
              margin="xsmall"
              textAlign="center"
              style={{ fontWeight: 400 }}
            >
              {heading}
            </Heading>
            <Paragraph textAlign="center" margin="xsmall" style={weight}>
              {message}
            </Paragraph>
            <Paragraph margin="xsmall" style={weight}>
              {farewell}
            </Paragraph>
          </Box>
        </Layer>
      )}
    </ResponsiveContext.Consumer>
  )
}

export default SmallMessage
